/*.table-icon {
    color: rgba($primary-color, 0.7);
    padding: 3px;
    &:hover{
      color: $primary-color;
    }
  &:before {
    font-size: 25px;
    }
  }
*/
