@font-face {
  font-family: 'Lato';
  src: url(/assets/fonts/Lato.ttf);
}

@font-face {
  font-family: 'Lato-Bold';
  src: url(/assets/fonts/Lato-Bold.ttf);
}

@font-face {
  font-family: 'Lato-Black';
  src: url(/assets/fonts/Lato-Black.ttf);
}


@font-face {
  font-family: 'Baloo 2';
  src: url(/assets/fonts/Baloo2.ttf);
}

$color-invalid:#f44336; //from material design
$c-gray:rgb(125, 125, 125);

$font-size-s: 9px;
$font-size-m: 12px;
$font-size-l: 18px;

$brand-header-heigth: 62px;
$utils-header-heigth: 56px;

$primary-color:#799dfb;
$primary-color-dark:#6a66d7;

$primary-color-noir: #262f51;
$primary-color-dark-noir: #34408c;

$primary-color-05: rbga($primary-color, 0.5);
$primary-color-01: rbga($primary-color, 0.1);
$secondary-color: #7cc5b8;

$disabled-color-1:#c85664;
$disabled-color-2:#d18668;
$disabled-color-text: #ead4cd;
$question-color: #f7b438;

$save-color-ok-1: #94c190;
$save-color-ok-2: #beecb9;

$background-color: #F6F6F6;

.primary-row{
    height: calc(100vh - #{$brand-header-heigth} - #{$utils-header-heigth});
    overflow-y: auto;
}

:root {
  --baloo-regular: 400 18px/24px 'Baloo 2', cursive;
  --baloo-bold: 600 18px/24px 'Baloo 2', cursive;
  --lato-regular: 400 12px/14px 'Lato', sans-serif;
  --lato-bold: 700 12px/14px 'Lato-Bold', sans-serif;
}

body {
  background-color: $background-color !important;
}


.form-section .mdc-text-field {
  background-color: rgb(255 255 255 / 70%) !important;
}
td,
input,
html,
h1,
h2,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font: var(--lato-regular);
}

h3 {
  font: var(--baloo-bold);
}


th {
  font: var(--baloo-bold);
  font-size: 13px;
}

.mdc-text-field__input, mat-label {
  font-family: 'Baloo 2' !important;
}
