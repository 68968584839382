@import "../global.scss";
button {
  &:focus {
    outline: none !important;
  }

  &.btn-primary {
    background: linear-gradient( 70deg, $primary-color-dark-noir, $primary-color-noir);
    color: white !important;
    border-color: #262f51 !important;
    font-size: 17px;
    padding: 1px 40px;

    &.margin_top_20 {
      margin-top: 20px;
    }

    &:hover {
      background: linear-gradient( 70deg, $primary-color-dark, $primary-color) !important;
    }
  }
}

.icon-btn {
  color: $primary-color-dark-noir !important;
  min-width: 50px !important;
  margin: 0 10px 0 0 !important;
  height: 47px !important;

  &.btn-primary {
    padding: 0;
  }

  i {
    padding: 6px 1px !important;
    font-size: 32px;

    &.fa.fa-users {
      font-size: 28px;
    }
  }

  &.inline {
    position: relative;
  }

  &:last-child {
    margin: 0 !important;
  }

  &:focus {
    outline: none;
    background: transparent;
  }

  &.disabled:hover, &.disabled:focus {
    background: linear-gradient( 70deg, $disabled-color-1, $disabled-color-2);

    i {
      -webkit-text-fill-color: $disabled-color-text;
    }
  }

  &.disabled i {
    background: linear-gradient( 70deg, $disabled-color-2, $disabled-color-1);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &.save-ok:hover, &.save-ok:focus {
    background: #ffffff;
    cursor: default;
  }

  &.save-ok i {
    background: linear-gradient( 70deg, $save-color-ok-2, $save-color-ok-1);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &.image-btn:hover, &.image-btn:focus {
    background: linear-gradient( 43deg, #f5f5f5, #ffffff);
  }

  &:hover, &:focus {
    background: linear-gradient( 70deg, $primary-color-dark, $primary-color);
    color: white !important;
  }

  &.deselected:focus { //Esto es para el aspa de las modales, que por defecto está focus.
    background: transparent;

    i {
      color: $primary-color-dark-noir !important;
    }

    .mat-button-focus-overlay {
      opacity: 0;
    }

    &:hover {
      background: linear-gradient( 70deg, $primary-color-dark, $primary-color);

      i {
        color: white;
      }
    }
  }

  &.cancel-button {
    height: 29px !important;
    width: 29px !important;
    min-width: 0 !important;
    margin: 10px !important;

    i {
      font-size: 20px;
    }
  }
}


  .form-workflow-buttons {
    display: grid;
    grid-template-columns: auto auto auto auto;
    grid-gap: 8px;
    align-content: center;
    height: 55px;
    padding-right: 10px;
  }



  .answers-scroll {
    .mat-mdc-text-field-wrapper {
      height: 47px !important;
    }
  }

  .mat-mdc-checkbox label {
    cursor: pointer;
    white-space: nowrap;
    position: relative;
    top: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
