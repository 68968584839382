@import "../global.scss";

.mat-button-focus-overlay {
  opacity: 0 !important;
}

.mat-menu-content {
  padding: 0px !important;
}

.mat-menu-panel {
  min-height: 0 !important;
}

button.mat-menu-item {
  line-height: 35px;
  height: 35px;
}

.mat-form-field-appearance-outline .mat-form-field-outline-thick, .mat-form-field-flex:hover .mat-form-field-outline {
  color: $primary-color !important; 
}
