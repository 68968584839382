
h2 {
  color: $primary-color !important;
}

mat-dialog-content{
  max-height: calc(100vh - 200px)!important;
}

.modal-window {
  .container-wd {
    background-color: white;
    border-radius: 5px;
    box-shadow: 1px 1px 4px rgb(0 0 0 / 20%);
    margin: 20px 20px 0px 20px;
    margin: 0;
    padding: 15px 15px 20px;
    background-color: #f5f5f5;
    width: inherit;
  }

  .step-form-input {
    width: 100%;

    &.double {
      grid-column: 1 / span 2;
    }
  }


  .header-info {
    display: grid !important;
    grid-template-columns: 50px 1fr 50px;
    align-items: center;
    width: 100% !important;
  }

  .row {
    margin-left: 0;
    margin-right: 0;
  }

  .mat-form-field {
    width: 100%;
  }

  .mat-error {
    display: inline-block;
    margin-right: 8px;
    color: #f44336;
  }

  .icon-btn {
    line-height: 0.5;
    vertical-align: bottom;
  }

  .button-area {
    padding: 20px 0 0 0;
    float: right;
  }

  .save-button {
    min-width: 37px;
    margin: 7px;
    height: 32px;
    margin: 7px 8px 4px 8px;
    background: url(/assets/img/save-step/save-step-hover.svg) no-repeat;
  }

  .icon-btn {
    padding: 1px 0px;
    min-width: 48px;
    margin: 0 10px 0 0 !important;
  }

  button.btn-primary {
    background: linear-gradient(70deg, #34408c, #262f51);
    color: white !important;
    border-color: #262f51 !important;
    font-size: 17px;
    padding: 1px 40px;
  }


  i.fa.fa-close {
    font-size: 20px;
    padding: 0 !important;
    line-height: 28px;
  }
}


.with-close-button {
  display: grid;
  grid-template-columns: 1fr auto;
  background: #fbfbfb;

  img.step-icon {
    width: 34px;
    line-height: 0.5;
    vertical-align: bottom;
  }
  }
