.uploadPreview-component.img-container {
  max-width: calc(100% - 20px);
  max-height: 250px;
  min-height: 100px;
  min-width: 100px;
  overflow: hidden;
  background: #e7e7e7;
  border-radius: 6px;
  border: 1px solid #62626230;
  align-items: center;
  margin: auto;
  display: grid;
  box-shadow: inset 0px 1px 2px #00000021;
  width: fit-content;

  img#uploadPreview {
    max-height: 250px;
    max-width: 100%;
    cursor: pointer;
    margin: auto;
  }
}


.form-columns {
  display: grid;
  grid-template-columns: 70% 30%;
  grid-gap: 30px;

  .form-column-preview {
    align-self: center;
  }
}

@media screen and (max-width: 960px) {
  .form-columns {
    grid-template-columns: 1fr !important;
    margin-bottom: 24px;
  }
}
