.mat-badge-content,

.mat-primary .mat-pseudo-checkbox-checked,
.mat-primary .mat-pseudo-checkbox-indeterminate,

.mat-toolbar.mat-primary,

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
    background: $primary-color;
}


.mat-primary .mat-option.mat-selected:not(.mat-option-disabled),

.mat-button.mat-primary,
.mat-icon-button.mat-primary,
.mat-stroked-button.mat-primary,

.mat-datepicker-toggle-active,

.mat-form-field.mat-focused.mat-primary .mat-select-arrow,

.mat-form-field.mat-focused .mat-form-field-label,

.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after,

.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
    color: $primary-color;
}

.mat-form-field.mat-focused .mat-form-field-label, .mat-form-field.mat-focused .mat-form-field-required-marker {
    color: $primary-color !important;
}


.mat-input-element {
    caret-color: $primary-color;
}


.mat-progress-spinner circle,
.mat-spinner circle {
    stroke: $primary-color;


    .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
        border-color: $primary-color;
    }


    .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb,

    .mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element,

    .mat-primary .mat-slider-thumb,
    .mat-primary .mat-slider-thumb-label,
    .mat-primary .mat-slider-track-fill,

    .mat-step-header .mat-step-icon-selected,
    .mat-step-header .mat-step-icon-state-done,
    .mat-step-header .mat-step-icon-state-edit,

    .mat-tab-group.mat-primary .mat-ink-bar,
    .mat-tab-nav-bar.mat-primary .mat-ink-bar,

    .mat-tab-group.mat-background-primary .mat-tab-header,
    .mat-tab-group.mat-background-primary .mat-tab-header-pagination,
    .mat-tab-group.mat-background-primary .mat-tab-links,
    .mat-tab-nav-bar.mat-background-primary .mat-tab-header,
    .mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination,
    .mat-tab-nav-bar.mat-background-primary .mat-tab-links,

    .mat-radio-button.mat-primary .mat-radio-inner-circle,
    .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
    .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple,
    .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple,

    .mat-fab.mat-primary,
    .mat-flat-button.mat-primary,
    .mat-mini-fab.mat-primary,
    .mat-raised-button.mat-primary,

    .mat-button.mat-primary .mat-button-focus-overlay,
    .mat-icon-button.mat-primary .mat-button-focus-overlay,
    .mat-stroked-button.mat-primary .mat-button-focus-overlay,

    .mat-progress-bar-fill::after,

    .mat-calendar-body-selected,

    .mat-checkbox-checked.mat-primary .mat-checkbox-background,
    .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background,

    .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary,

    .mat-form-field.mat-focused .mat-form-field-ripple {
        background-color: $primary-color;
	}

}


.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element, .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: $primary-color !important;
}
.mat-checkbox-checked.mat-accent .mat-checkbox-background, .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
  background-color: $primary-color !important;
}
