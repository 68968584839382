
mat-chip:hover {
  background: linear-gradient(to left, #e55656 0%, #ef8989 100%);

  .mdc-evolution-chip__text-label {
    color: white !important;
  }

  .mat-mdc-chip-remove {
    opacity: 1;

     .mat-icon {
      color: white;
    }
  }
}
