/* Import other style files */
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import '../node_modules/@syncfusion/ej2-angular-diagrams/styles/material.css';
@import '../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
@import '../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../node_modules/@syncfusion/ej2-lists/styles/material.css';
@import '../node_modules/@syncfusion/ej2-angular-dropdowns/styles/material.css';
@import "app/styles/global.scss";
@import "app/styles/animate.scss";
@import "app/styles/material/indigo.scss";
@import "app/styles/components/components.scss";


